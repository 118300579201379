import { createApp } from 'vue'

import JournalPageDsi from "@/components/JournalPageDsi.vue";
import JournalEntry from "@/components/JournalEntry.vue";
import TabsVertical from "@/components/TabsVertical.vue";
import TabItem from "@/components/TabItem.vue";


Array.from(document.getElementsByClassName("dsi-journal")).forEach((el) => {
    createApp({}).component("journal-page-dsi", JournalPageDsi).mount(el);
});

Array.from(document.getElementsByClassName("journal-app")).forEach((el) => {
    createApp({}).component("journal-entry", JournalEntry).mount(el);
});

Array.from(document.getElementsByClassName("tabs-app")).forEach((el) => {
    createApp({}).component("tabs-vertical", TabsVertical).component("tab-item", TabItem).mount(el);
});
