window.urlSwitch = function urlSwitch(new_language) {
  let url = window.location.href.split("/")
  let currentLanguage = url[3];
  url[3] = new_language
  let new_url = url.join("/")
  window.location = new_url
}

$("document").ready(function() {
  $.ajaxSetup({
    beforeSend: function(xhr, settings) {
      if (
        settings.type === "POST" ||
        settings.type === "PUT" ||
        settings.type === "DELETE"
      ) {
        function getCookie(name) {
          var cookieValue = null;
          if (document.cookie && document.cookie !== "") {
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              var cookie = jQuery.trim(cookies[i]);
              if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(
                  cookie.substring(name.length + 1)
                );
                break;
              }
            }
          }
          return cookieValue;
        }
        if (
          !(/^http:.*/.test(settings.url) || /^https:.*/.test(settings.url))
        ) {
          // Only send the token to relative URLs i.e. locally.
          xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
        }
      }
    }
  });
});

// get the height of the fixed navbars and content for responsive spacing with the footer
$(document).ready(contentHeight);
$(window).resize(contentHeight);
$(window).resize(autocompleteWidth);

function contentHeight() {
  var navHeight;
  if ($(".navbar.secondary")[0]) {
    navHeight = $(".navbar.secondary").outerHeight();
  } else {
    navHeight = 0;
  }

  var headerHeight = $(".navbar.primary").outerHeight();
  var headerTotal = headerHeight + navHeight;
  $(".body-wrapper").css("top", headerTotal);

  var contentHeight = $(".content-wrapper").outerHeight();
  $(".container-wrapper").css("min-height", contentHeight);
}
function autocompleteWidth() {
  var acWidth = $("#autocompleteWrapper").outerWidth();
  $("lxp-suggestions.jumbodash").css("width", acWidth);
}
autocompleteWidth();

$(function() {
  // dashboard rotate images
  var jumbotron = [
    "/static/img/demo/landscape-dashboard-balloons.jpg",
    "/static/img/demo/landscape-dashboard-hikers.jpg",
    "/static/img/demo/landscape-dashboard-astronaut.jpg"
  ];

  var size = jumbotron.length;
  var x = Math.floor(size * Math.random());
  $(".jumbotron.dashboard").css({
    "background-image": "url(" + jumbotron[x] + ")"
  });

  // footer position adjust for bootstrap collapse - WIP
  $(".collapse").on("show.bs.collapse", function() {
    $(".footer").hide();
  });
  $(".collapse").on("shown.bs.collapse", function() {
    contentHeight();
    $(".footer").show();
  });
  $(".collapse").on("hidden.bs.collapse", function() {
    contentHeight();
  });

  // change the collapse button text and icon
  $(".btn-hide-text").hide();
  $(".collapse.paths").on("show.bs.collapse", function(e) {
    var clicked = $(document).find("[href='#" + $(e.target).attr("id") + "']");
    $(clicked)
      .parent()
      .find(".btn-show-text")
      .hide();
      $(clicked)
      .parent()
      .find(".btn-hide-text")
      .show();
    $(clicked)
      .parent()
      .find(".fa-angle-down")
      .removeClass("fa-angle-down")
      .addClass("fa-angle-up");
  });
  $(".collapse.paths").on("hide.bs.collapse", function(e) {
    var clicked = $(document).find("[href='#" + $(e.target).attr("id") + "']");
    $(clicked)
      .parent()
      .find(".btn-show-text")
      .show();
    $(clicked)
      .parent()
      .find(".btn-hide-text")
      .hide();
    $(clicked)
      .parent()
      .find(".fa-angle-up")
      .removeClass("fa-angle-up")
      .addClass("fa-angle-down");
  });

  // learning plan show/hide acct for expanded_toggle
  $(".collapse.paths").on("hide.bs.collapse", function(e) {
    var clicked = $(document).find("[href='#" + $(e.target).attr("id") + "']");
    if ($(clicked).hasClass('collapsed')) {}
    else {
      $(clicked)
        .parent()
        .find(".btn-lp-show-text")
        .show();
      $(clicked)
        .parent()
        .find(".btn-lp-hide-text")
        .hide();
      $(clicked)
        .parent()
        .find(".fa-angle-up")
        .removeClass("fa-angle-up")
        .addClass("fa-angle-down");
    }
  });
  $(".collapse.paths").on("show.bs.collapse", function(e) {
    var clicked = $(document).find("[href='#" + $(e.target).attr("id") + "']");
    if ($(clicked).hasClass('collapsed')) {
      $(clicked)
        .parent()
        .find(".btn-lp-show-text")
        .hide();
        $(clicked)
        .parent()
        .find(".btn-lp-hide-text")
        .show();
      $(clicked)
        .parent()
        .find(".fa-angle-down")
        .removeClass("fa-angle-down")
        .addClass("fa-angle-up");
    }
    else {}
  });

  // learning journey - learning path collapse
  $(".btn-lj-hide-text").hide();
  $(".collapse.journey").on("hide.bs.collapse", function(e) {
    var clicked = $(document).find("[href='#" + $(e.target).attr("id") + "']");
    if ($(clicked).hasClass('collapsed')) {
    }
    else {
      $(clicked)
        .parent()
        .find(".btn-lj-show-text")
        .show();
      $(clicked)
        .parent()
        .find(".btn-lj-hide-text")
        .hide();
      $(clicked)
        .parent()
        .find(".fa-angle-up")
        .removeClass("fa-angle-up")
        .addClass("fa-angle-down");
    }
  });
  $(".collapse.journey").on("show.bs.collapse", function(e) {
    var clicked = $(document).find("[href='#" + $(e.target).attr("id") + "']");
    if ($(clicked).hasClass('collapsed')) {
      $(clicked)
        .parent()
        .find(".btn-lj-show-text")
        .hide();
        $(clicked)
        .parent()
        .find(".btn-lj-hide-text")
        .show();
      $(clicked)
        .parent()
        .find(".fa-angle-down")
        .removeClass("fa-angle-down")
        .addClass("fa-angle-up");
    }
    else {
    }
  });

  // get the learning plan summary - required learning count
  var requiredLearningCount = $(".card.required-learning .list-group").length;
  $(".badge.required-learning-count").text(requiredLearningCount);

  // six ways to jump start learning toggle
  $(".jump-start-learning .dropdown-item").each(function() {
    var title = $(this).text();
    var order = $(this)
      .attr("class")
      .replace("dropdown-item jsl-dropdown-", "");

    $(this).on("click", function() {
      $(".jsl-title").text(title);
      $(".jsl-toggle").hide();
      $(".jsl-set-" + order).show();
    });
  });

  // dashboard to lp bucket anchor buttons
  if (window.location.href.indexOf("#CreateClientValue") > -1) {
    $("#collapse_CreateClientValue").collapse("show");
    $("html, body").animate(
      {
        scrollTop: $("#CreateClientValue").offset().top - 200
      },
      "slow"
    );
  }
  if (window.location.href.indexOf("#EnableInnovation") > -1) {
    $("#collapse_EnableInnovation").collapse("show");
    $("html, body").animate(
      {
        scrollTop: $("#EnableInnovation").offset().top - 200
      },
      "slow"
    );
  }
  if (window.location.href.indexOf("#DeliverBusinessResults") > -1) {
    $("#collapse_DeliverBusinessResults").collapse("show");
    $("html, body").animate(
      {
        scrollTop: $("#DeliverBusinessResults").offset().top - 200
      },
      "slow"
    );
  }
  if (window.location.href.indexOf("#DevelopTeams") > -1) {
    $("#collapse_DevelopTeams").collapse("show");
    $("html, body").animate(
      {
        scrollTop: $("#DevelopTeams").offset().top - 200
      },
      "slow"
    );
  }

  // my progress graphs - get value and animate
  $(".progress-graph").each(function() {
    var bar = $(this).find(".progress-percent");
    var value = $(this).find("span");
    var percent = parseInt($(value).text(), 10);

    // change the color based on completion
    if (percent <= 33) {
      $(bar).css({
        "border-bottom-color": "#cb181f",
        "border-right-color": "#cb181f"
      });
    } else if (percent >= 34 && percent <= 65) {
      $(bar).css({
        "border-bottom-color": "#eca934",
        "border-right-color": "#eca934"
      });
    } else if (percent >= 66) {
      $(bar).css({
        "border-bottom-color": "#5eb6e4",
        "border-right-color": "#5eb6e4"
      });
    } else {
      $(bar).css({
        "border-bottom-color": "#efefef",
        "border-right-color": "#efefef"
      });
    }

    // animate the progress bars
    $({ p: 0 }).animate(
      { p: percent },
      {
        duration: 1500,
        easing: "swing",
        step: function(p) {
          $(bar).css({
            transform: "rotate(" + (45 + p * 1.8) + "deg)"
          });
          $(value).text(p | 0);
        }
      }
    );
  });

  // goal date - datepicker
  $(".goal-date").datepicker({
    todayBtn: false,
    orientation: "bottom left",
    autoclose: true,
    todayHighlight: false,
    format: "M d, yyyy",
    forceParse: false
  });

  $(".goal-date").change(function() {
    var url = $(this).attr("data-url");

    var data = {
      goal_date: $(this).val(),
      obj_type: $(this).attr("data-type"),
      obj_id: $(this).attr("data-id")
    };
    data = JSON.stringify(data);

    $.ajax({
      type: "POST",
      url: url,
      dataType: "json",
      data: data,
      success: function(data) {
        // console.log(data);
      },
      fail: function(data) {
        // console.log(data);
      }
    });
  });

  if ($(".resource-div").length) {
    if ($(".resource-div").attr("data-selfcheck") !== "True") {
      var endpoint = $(".resource-div").attr("data-endpoint");
      var obj_id = $(".resource-div").attr("data-obj-id");
      var obj_type = $(".resource-div").attr("data-obj-type");
      var progress_status = "Completed";

      var data = JSON.stringify({
        obj_type: obj_type,
        obj_id: obj_id,
        progress_status: progress_status
      });
      // console.log(data);
      // console.log(endpoint);

      $.ajax({
        type: "POST",
        url: endpoint,
        dataType: "json",
        data: data,
        success: function(data) {
          // console.log(data);
        },
        fail: function(data) {
          // console.log(data);
        }
      });
    }
  }

  if ($(".resource-div").length) {
    // console.log("loaded resource timer");
    var initial_seconds = $(".resource-div").attr("data-initial-seconds");
    var max_seconds = $(".resource-div").attr("data-max-seconds");
    var timer_endpoint = $(".resource-div").attr("data-timer-endpoint");
    // console.log("initial seconds ", initial_seconds);
    // console.log(timer_endpoint);

    var start_time = new Date().getTime();

    window.setInterval(function() {
      // every 5 seconds.
      var stop_time = new Date().getTime();
      var elapsed_amount = (stop_time - start_time) / 1000;

      var total_seconds = parseInt(initial_seconds) + parseInt(elapsed_amount);
      // console.log(total_seconds);
      // console.log(max_seconds);

      if (total_seconds < max_seconds) {
        var data = JSON.stringify({ seconds: total_seconds });
        // console.log(data);
        $.ajax({
          type: "POST",
          url: timer_endpoint,
          dataType: "json",
          data: data,
          success: function(data) {
            // console.log(data);
          },
          fail: function(data) {
            // console.log(data);
          }
        });
      }
    }, 5000);
  }


  // Auto set self-status progress on Launch.
  if ($(".auto-progress").length) {
    $(".auto-progress").click(function() {
      var launch_button = $(this);
      var endpoint = launch_button.attr("endpoint");
      var obj_id = launch_button.attr("data-obj-id");
      var obj_type = launch_button.attr("data-obj-type");

      var data = JSON.stringify({
        obj_type: obj_type,
        obj_id: obj_id,
      });
      // console.log(data);
      // console.log(endpoint);

      $.ajax({
        type: "POST",
        url: endpoint,
        dataType: "json",
        data: data,
        success: function(data) {
          // console.log(data);
          $('#status_select_' + obj_id).val(data.status);
          $('#status_select_' + obj_id).addClass(data.status.toLowerCase());
          //if (data.status == 'Completed'){
            //$('#status_select_' + obj_id).val(data.status);
            //$('#status_select_' + obj_id).addClass(data.status.toLowerCase());
          //}else{
            //$('#status_select_' + obj_id).val('In-Progress');
            //$('#status_select_' + obj_id).addClass('in-progress');
          //}
        },
        fail: function(data) {
          // console.log(data);
        }
      });
    });
  }


  // status class color
  $("div.status-box").each(function() {
    var statusClass = $(this)
      .text()
      .toLowerCase()
      .replace(/-|\s|•\s+/g, "");
    $(this).addClass(statusClass);
    $(this).closest(".list-group").addClass(statusClass);
  });

    // self-status status class
    $("lxp-self-progress").each(function() {
      if ($(this).attr('current_status') == 'Not-Started') {
        $(this).closest(".list-group").addClass("notstarted");
      }
      else if ($(this).attr('current_status') == 'In-Progress') {
        $(this).closest(".list-group").addClass("inprogress");
      }
      else if ($(this).attr('current_status') == 'Completed') {
        $(this).closest(".list-group").addClass("completed");
      }
    });

    $('.required_learning.container').find('.list-group.completed').hide();
    $('.learning_paths.container').find('.list-group.completed').hide();
    $('.playlist.container').find('.list-group.completed').hide();

    contentHeight();


  // required learning - active/all switch
  $("#requiredSwitch").click(function() {
    var required = $(this).closest(".required_learning");
    var listgroup = $(required).find(".list-group");
    var status = $(listgroup).find(".status-box");
    var selfstatus = $(listgroup).find("select.learning-plan-select");

    $(required).find(".active-all-switch .active-label").toggleClass("selected");
    $(required).find(".active-all-switch .all-label").toggleClass("selected");

    $(status).each(function() {
      if ($(required).find(".all-label").hasClass("selected")) {
        $(required).find("div.status-box").each(function() {
          $(this).closest(".list-group").fadeIn();
        });
      }
      else {
        if ($(this).closest(".list-group").hasClass("completed")) {
          $(this).closest(".list-group").fadeOut();
        }
      }
    });

    $(selfstatus).each(function() {
      if ($(required).find(".all-label").hasClass("selected")) {
        $(required).find("select.learning-plan-select").each(function() {
          $(this).closest(".list-group").fadeIn();
        });
      }
      else {
        if ($(this).closest(".list-group").hasClass("completed")) {
          $(this).closest(".list-group").fadeOut();
        }
      }
    });
    setTimeout(function() { contentHeight(); }, 500);
  });

  // learning path - active/all switch
  $("#learningPathSwitch").click(function() {
    var learningpaths = $(this).closest(".learning_paths");
    var listgroup = $(learningpaths).find(".list-group");
    var status = $(listgroup).find(".status-box");
    var selfstatus = $(listgroup).find("select.learning-plan-select");

    $(learningpaths).find(".active-all-switch .active-label").toggleClass("selected");
    $(learningpaths).find(".active-all-switch .all-label").toggleClass("selected");

    $(status).each(function() {
      if ($(learningpaths).find(".all-label").hasClass("selected")) {
        $(learningpaths).find("div.status-box").each(function() {
          $(this).closest(".list-group").fadeIn();
        });
      }
      else {
        if ($(this).closest(".list-group").hasClass("completed")) {
          $(this).closest(".list-group").fadeOut();
        }
      }
    });

    $(selfstatus).each(function() {
      if ($(learningpaths).find(".all-label").hasClass("selected")) {
        $(learningpaths).find("select.learning-plan-select").each(function() {
          $(this).closest(".list-group").fadeIn();
        });
      }
      else {
        if ($(this).closest(".list-group").hasClass("completed")) {
          $(this).closest(".list-group").fadeOut();
        }
      }
    });
    setTimeout(function() { contentHeight(); }, 500);
  });

  // playlist - active/all switch
  $("#playlistSwitch").click(function() {
    var playlist = $(this).closest(".playlist");
    var listgroup = $(playlist).find(".list-group");
    var status = $(listgroup).find(".status-box");
    var selfstatus = $(listgroup).find("select.learning-plan-select");

    $(playlist).find(".active-all-switch .active-label").toggleClass("selected");
    $(playlist).find(".active-all-switch .all-label").toggleClass("selected");

    $(status).each(function() {
      if ($(playlist).find(".all-label").hasClass("selected")) {
        $(playlist).find("div.status-box").each(function() {
          $(this).closest(".list-group").fadeIn();
        });
      }
      else {
        if ($(this).closest(".list-group").hasClass("completed")) {
          $(this).closest(".list-group").fadeOut();
        }
      }
    });

    $(selfstatus).each(function() {
      if ($(playlist).find(".all-label").hasClass("selected")) {
        $(playlist).find("select.learning-plan-select").each(function() {
          $(this).closest(".list-group").fadeIn();
        });
      }
      else {
        if ($(this).closest(".list-group").hasClass("completed")) {
          $(this).closest(".list-group").fadeOut();
        }
      }
    });
    setTimeout(function() { contentHeight(); }, 500);
  });





  // search filter sm expand
  $(".search-filter-expand").click(function() {
    $(".search-filter .filter-group, .search-filter .search-buttons").toggle();
    contentHeight();
  });

  // search filter fixed position
  // if ($(".search .search-filter").length) {
  //   $( window ).scroll(function() {
  //     var scroll = $(window).scrollTop();
  //     if (scroll > 214) {
  //       $(".search .search-filter").addClass("filter-fixed");
  //     } else {
  //       $(".search .search-filter").removeClass("filter-fixed");
  //     }
  //   });
  // }

  /*
  // Add to plan button.
  $(".add-to-plan").click(function(event){
      event.preventDefault();
      var url = $(this).attr('data-url');
      // console.log(url);
      $.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          data: {
              'path_id': 'test'
          },
          success: function(data) {
            // console.log(data);
          }
        });
    });

  // Add to playlist button.
  $(".add-to-playlist").click(function(event){
      event.preventDefault();
      var url = $(this).attr('data-url');
      // console.log(url);
      $.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          data: {
              'path_id': 'test'
          },
          success: function(data) {
            // console.log(data);
          }
        });
    });
*/

  // learning plan - navbar anchors to change while scrolling
  // var nav = $('.navbar.secondary.learning-plan .navbar-nav');
  //
  // nav.find('a').on('click', function () {
  //   $('.secondary .nav-item').removeClass('active');
  //   $(this).addClass('active');
  // });

  $(".toggle-path").click(function() {
    // console.log("toggling state");
    var url = $(this).attr("data-url");

    var up_id = $(this).attr("data-up-id");
    // console.log(up_id);

    var toggle_state = false;
    if ($(this).hasClass("collapsed")) {
      toggle_state = true;
    } else {
      toggle_state = false;
    }
    // console.log(toggle_state);

    let data = { up_id: up_id, toggle_state: toggle_state };
    data = JSON.stringify(data);
    // console.log(data);

    $.ajax({
      type: "POST",
      url: url,
      dataType: "json",
      data: data,
      success: function(data) {
        // console.log(data);
      },
      fail: function(data) {
        // console.log(data);
      }
    });
  });

  // Journal.
  //
  // Main Journal Template click in left column
  $(".journal-path").click(function(event) {
    event.preventDefault();
    contentHeight();

    var path_id = $(this).data("path-id");
    const JournalArea = $("#journal_text_" + path_id)
    console.log(path_id, $("#journal_text_" + path_id))

    $(".journal-path").removeClass("active");
    $(".journal-path")
      .find(".fa-chevron-circle-down")
      .removeClass("fa-chevron-circle-down")
      .addClass("fa-chevron-circle-right");

    $(this).addClass("active");

    if ($(this).find(".fa-chevron-circle-right").length !== 0) {
      $(this)
        .find(".fa-chevron-circle-right")
        .removeClass("fa-chevron-circle-right")
        .addClass("fa-chevron-circle-down");
    }
    // hide all the textareas
    $(".journal-text").hide();

    // show the related clicked
    JournalArea.show();
    $("#journal_text_" + path_id).show()

  });

  //get the journals
  if ($("#journal-resource-block").length) {
    // console.log('load journal journal-resource-block ')
    const JournalButton = $("#journal-save-button")
    let url = JournalButton.data("url");
    const obj_id = JournalButton.data("obj_id");
    const obj_type = JournalButton.data("obj_type");
    url = `${url}?obj_id=${obj_id}&obj_type=${obj_type}`

    $.ajax({
      type: "GET",
      url: url,
      success: function(JournalData) {
        if(JournalData[0]) {
          JournalButton.data("pk", JournalData[0].id);
          const rows = JournalData[0].data
          console.log(rows)
          $.each(rows, function(i, row) {
            $("#note_" + row.section).val(row.note);
          });
        }
      },
      fail: function(data) {
        // console.log(data);
      }
    });
  };

  // Journal data ajax  post-data.
  $(".journal-save").click(function(event) {
    event.preventDefault();
    const JournalButton = $(this)
    let url = JournalButton.data("url");
    const obj_id = JournalButton.data("obj_id");
    const obj_type = JournalButton.data("obj_type");
    const pk = JournalButton.data("pk");

    let method = 'POST'
    if (pk) {
      url = `${url}${pk}/`
      method = 'PUT'
    } else {
      url = `${url}?obj_id=${obj_id}&obj_type=${obj_type}`
    }

    const PostData = {}
    PostData.obj_id = obj_id;
    PostData.obj_type = obj_type;
    PostData.is_general = (obj_type === 'general');
    PostData.data = [];

    $(".journal-text-area").each(function() {
      const section = {};
      section.title = $(this).data("title");
      section.section = $(this).data("section");
      section.note = $(this).val();
      PostData.data.push(section);
    });

    // console.log(JSON.stringify(PostData))

    $.ajax({
      type: method,
      url: url,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify(PostData),
      success: function(JournalData) {
        JournalButton.data("pk", JournalData.id);
      },
      fail: function(data) {
        // console.log(data);
      }
    });
  });

   // Journal data ajax  post-data.
  $(".general-journal-save").click(function(event) {
    event.preventDefault();
    const JournalButton = $(this)
    let url = JournalButton.data("url");
    const pk = JournalButton.data("pk");
    const obj_id = 'general';
    const obj_type = 'general';

    let method = 'POST'
    if (pk) {
      url = `${url}${pk}/`
      method = 'PUT'
    } else {
      url = `${url}?obj_id=${obj_id}&obj_type=${obj_type}`
    }

    const PostData = {}
    PostData.obj_id = 'general';
    PostData.obj_type = 'general';
    PostData.is_general = true;
    PostData.data = [];

    const generalTextArea = $("#general-journal-text-area")
    const section = {};
    section.title = generalTextArea.data("title");
    section.section = generalTextArea.data("section");
    section.note = generalTextArea.val();
    PostData.data.push(section);

    // console.log(JSON.stringify(PostData))

    $.ajax({
      type: method,
      url: url,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify(PostData),
      success: function(JournalData) {
        JournalButton.data("pk", JournalData.id);
      },
      fail: function(data) {
        // console.log(data);
      }
    });
  });


  // Journal email results.
  $(".journal-email").click(function(event) {
    event.preventDefault();
    var learning_path_title = $(this).attr("data-path-title");

    var emailBody = "";
    $(this)
      .parent("div")
      .children(".journal-section-div")
      .each(function() {
        var section_title = $(this)
          .find(".journal-section-title")
          .text();
        if (section_title.length !== 0) {
          emailBody += section_title;
          emailBody += "\r\n\r\n";
        } else {
          section_title = $(this)
            .find(".journal-final-title")
            .text();
          // console.log(section_title);
          emailBody += section_title;
          emailBody += "\r\n\r\n";
        }

        var section_question = $(this)
          .children(".journal-question")
          .text();
        if (section_question.length !== 0) {
          emailBody += section_question;
          emailBody += "\r\n";
        }

        var section_entry = $(this)
          .children(".journal-text-area")
          .val();
        emailBody += section_entry;
        emailBody += "\r\n\r\n";
      });

    var subject = "Journal Entries for " + learning_path_title;
    window.location =
      "mailto:?subject=" +
      encodeURIComponent(subject) +
      "&body=" +
      encodeURIComponent(emailBody);
  });

  // offerings table - hide empty rows
  if ($(".offerings-table").find(".offering").length !== 0) {
    if ($(".no-offering").length > 1) {
      $(".no-offering").hide();
      $(".no-offering")
        .first()
        .show();
    } else {
      $(".no-offering").hide();
    }
  } else {
    $(".no-offering").hide();
    $(".no-offering")
      .first()
      .show();
  }

  // offering table - sort by date

  $(".offerings-table").tablesorter({
    headers: {
      0: { sorter: false },
      3: { sorter: false },
      4: { sorter: false },
      5: { sorter: false }
    }
  });

  // open a learning path if directly linked to on learning plan
  location.hash && $(location.hash + ".collapse").collapse("show");

  // filter out completed required learning on my learning plan
  // $(".required_learning .list-group").each(function(i) {
  //   if (!$(this).hasClass("course")) {
  //     var details = $(this).find(".content-details");
  //     var completed = $(details).find(".completed");
  //     $(completed)
  //       .closest(".list-group")
  //       .remove();
  //   }
  // });

  if ($(".required_learning.container").find(".list-group").length == 0) {
    $("<p>You have no Required Learning at this time.</p>").insertAfter(
      ".required_learning.container h2"
    );
  }
});

// Progress Snapshot API
if ($(".my-progress").length) {
  // console.log("my progress snapshot api called");
  var url = $(".my-progress").attr("data-url");
  // console.log(url);

  $.ajax({
    type: "GET",
    url: url,
    success: function(data) {
      // console.log(data);

      $("#last_visit_count").text(data.last_visit_count);
      $("#learning_count").text(data.learning_count);
      $("#learning_count_previous").text(data.learning_count_previous);
      $("#completed_duration").text(data.completed_duration);
      $("#completed_duration_previous").text(data.completed_duration_previous);
      $("#rl_remaining").text(data.rl_remaining);
      $("#cpd_total").text(data.cpd_total);

      if ($('#rl_remaining').text() == '0') {
        $('.rl_remaining-text').css('color', '#379C52');
      }
      else {
        $('.rl_remaining-text').css('color', '#e11b22');
      }

      // Required Learning Year-to-Date bars.
      var rl_not_started_pct = 0;
      var rl_in_progress_pct = 0;
      var rl_completed_pct = 0;

      if (data.rl_total !== 0) {
        rl_not_started_pct = (data.rl_not_started / data.rl_total) * 100;
        rl_in_progress_pct = (data.rl_in_progress / data.rl_total) * 100;
        rl_completed_pct = (data.rl_completed / data.rl_total) * 100;
      }

      // console.log(
      //   data.rl_not_started,
      //   data.rl_in_progress,
      //   data.rl_completed,
      //   data.rl_total
      // );
      // console.log(rl_not_started_pct, rl_in_progress_pct, rl_completed_pct);
      $("#rl-not-started").width(rl_not_started_pct + "%");
      $("#rl-not-started").text(data.rl_not_started);
      $("#rl-not-started").attr("aria-valuenow", data.rl_not_started);
      $("#rl-not-started").attr("aria-valuemax", data.rl_total);

      $("#rl-in-progress").width(rl_in_progress_pct + "%");
      $("#rl-in-progress").text(data.rl_in_progress);
      $("#rl-in-progress").attr("aria-valuenow", data.rl_in_progress);
      $("#rl-in-progress").attr("aria-valuemax", data.rl_total);

      $("#rl-completed").width(rl_completed_pct + "%");
      $("#rl-completed").text(data.rl_completed);
      $("#rl-completed").attr("aria-valuenow", data.rl_completed);
      $("#rl-completed").attr("aria-valuemax", data.rl_total);

      // My Learning Paths Year-to-Date bars.
      var paths_not_started_pct;
      var paths_in_progress_pct;
      var paths_completed_pct;

      if (data.path_progress_total !== 0) {
        paths_not_started_pct =
          (data.paths_not_started / data.path_progress_total) * 100;
        paths_in_progress_pct =
          (data.paths_in_progress / data.path_progress_total) * 100;
        paths_completed_pct =
          (data.paths_completed / data.path_progress_total_ytd) * 100;
      }

      // console.log(
      //   data.paths_not_started,
      //   data.paths_in_progress,
      //   data.paths_completed,
      //   data.path_progress_total
      // );
      // console.log(
      //   paths_not_started_pct,
      //   paths_in_progress_pct,
      //   paths_completed_pct
      // );

      $("#paths-not-started").attr("aria-valuemin");
      $("#paths-not-started").width(paths_not_started_pct + "%");
      $("#paths-not-started").text(data.paths_not_started);
      $("#paths-not-started").attr("aria-valuenow", data.paths_not_started);
      $("#paths-not-started").attr("aria-valuemax", data.path_progress_total);

      $("#paths-in-progress").width(paths_in_progress_pct + "%");
      $("#paths-in-progress").text(data.paths_in_progress);
      $("#paths-in-progress").attr("aria-valuenow", data.paths_in_progress);
      $("#paths-in-progress").attr("aria-valuemax", data.path_progress_total);

      $("#paths-completed").width(paths_completed_pct + "%");
      $("#paths-completed").text(data.paths_completed);
      $("#paths-completed").attr("aria-valuenow", data.paths_completed);
      $("#paths-completed").attr("aria-valuemax", data.path_progress_total_ytd);

      // place and get initial footer height.  Not sure why this needs to happen after this ajax call, shouldn't effect the content-wrapper height.
      var contentHeight = $(".content-wrapper").outerHeight();
      $(".container-wrapper").css("min-height", contentHeight);
    },
    fail: function(data) {
      // console.log(data);
      // place and get initial footer height.  Not sure why this needs to happen after this ajax call, shouldn't effect the content-wrapper height.
      var contentHeight = $(".content-wrapper").outerHeight();
      $(".container-wrapper").css("min-height", contentHeight);
    }
  });
}

// Progress Manager Snapshot API
if ($("#managed-user-table").length) {
  // console.log("manager snapshot api js loaded");

  var url = $("#managed-user-table").attr("data-url");

  $.ajax({
    type: "GET",
    url: url,
    success: function(data) {
      $.each(data, function() {
        // console.log(
        //   this.id,
        //   this.first_name,
        //   this.last_name,
        //   this.informal_count
        // );
        var table_row = "<tr class='managed-user'>";
        table_row += "<td>" + this.last_name + ", " + this.first_name + "</td>";
        table_row += "<td class='text-center'>" + this.last_login + "</td>";
        table_row += "<td class='text-center'>" + this.learning_count + "</td>";
        table_row += "<td class='text-center'>" + this.learning_count_previous + "</td>";
        table_row +=
        "<td class='text-center'>" + this.completed_duration + "</td>";
        table_row +=
        "<td class='text-center'>" + this.completed_duration_previous + "</td>";
        table_row += "<td class='text-center'>" + this.rl_total + "</td>";
        // table_row += "<td class='text-center'>" + this.cpd_total + "</td>";
        table_row +=
          "<td class='text-center' id='plan-button-" + this.id + "'></td>";
        table_row += "</tr>";
        $("#managed-user-table").append(table_row);

        var button =
          "<a class='btn btn-sm btn-teal load-plan' id='plan_toggle_" +
          this.id +
          "' data-url='api/managersnapshotplan/" +
          this.id +
          "/' data-id='" +
          this.id +
          "' role='button' aria-expanded='false' aria-controls='learning-plan-" +
          this.id +
          "'>View Learning Plan</a>";
        //var button = "<a class='btn btn-sm btn-teal load-plan' id='plan_toggle_" + this.id + "' data-url='api/managersnapshotplan/" + this.id + "/' data-id='" + this.id + "' data-toggle='collapse' href='#learning-plan-" + this.id + "' role='button' aria-expanded='false' aria-controls='learning-plan-" + this.id + "'>View Learning Plan</a>";

        $("#plan-button-" + this.id).append(button);

        $("#managed-user-table").append(
          "<tr id='managed-user-plan-" + this.id + "'></tr>"
        );
      });

      // place and get initial footer height based on api data
      function footerHeight() {
        var contentHeight = $(".content-wrapper").outerHeight();
        $(".container-wrapper").css("min-height", contentHeight);
      }
      setTimeout(footerHeight, 50);
    },
    fail: function(data) {
      // console.log(data);
    }
  });
}

// Progress Manager Snapshot API
if ($(".manager-snapshot").length) {
  // console.log("managed user plan api js loaded");

  // Load the template partial for the managed user's plan.  Manager Snapshot Plan Partial.
  $(".manager-snapshot").on("click", ".load-plan", function(event) {
    event.preventDefault();

    var plan_button = $(this);
    var url = $(this).attr("data-url");
    var user_id = $(this).attr("data-id");

    $("#managed-user-plan-" + user_id).load(url, function() {
      plan_button.removeClass("load-plan"); // remove load-plan to prevent spamming API hits.
      plan_button.addClass("plan-toggle"); // Add plan-toggle to adjust footer height after load.
      plan_button.attr("aria-expanded", true);

      var contentHeight = $(".content-wrapper").outerHeight();
      $(".container-wrapper").css("min-height", contentHeight);
    });
  });

  // For adjusting footer height and toggling plan visibility.
  $(".manager-snapshot").on("click", ".plan-toggle", function(event) {
    event.preventDefault();

    var plan_button = $(this);
    var plan_id = $(this).attr("data-id");

    if ($(this).hasClass("collapsed")) {
      $("#learning-plan-" + plan_id).show(0, function() {
        var contentHeight = $(".content-wrapper").outerHeight();
        $(".container-wrapper").css("min-height", contentHeight);
        plan_button.removeClass("collapsed");
        plan_button.attr("aria-expanded", true);
      });
    } else {
      $("#learning-plan-" + plan_id).hide(0, function() {
        var contentHeight = $(".content-wrapper").outerHeight();
        $(".container-wrapper").css("min-height", contentHeight);
        plan_button.addClass("collapsed");
        plan_button.attr("aria-expanded", false);
      });
    }
  });
}

// Preview-Alert Header Adjustments
function PreviewBanner() {
  var previewHeight = $(".preview-alert").outerHeight();
  $(".navbar.primary.preview").css("margin-top", previewHeight);
  $(".container-wrapper.preview").css("margin-top", previewHeight);

  $(".preview-alert .alert").on("closed.bs.alert", function() {
    $(".navbar.primary.preview").css("margin-top", "0");
    $(".container-wrapper.preview").css("margin-top", "0");
  });
}
$(document).ready(PreviewBanner);
$(window).resize(PreviewBanner);


// Simulation
if ($(".simulation .quiz").length) {
  // Get video SRC from the data-src on each collapse | no longer necessary
  // var $videoSrc;
  // if ($('#collapse_videoWrapper')[0]) {
  //   $videoSrc = $("#collapse_videoWrapper").data( "src" );
  //   console.log($videoSrc);
  //   $("#videomp4").attr('src', $videoSrc + ".mp4" );
  //   $("#videoogg").attr('src', $videoSrc + ".ogg" );
  //   $("#videoQuestion").attr('poster', $videoSrc + ".jpg" );
  //   // THIS is needed.
  //   $("#videoQuestion")[0].load();
  // }

  // Replay Button
  $('.replayVideo').click(function(e) {
    e.preventDefault;
    // console.log('Replay Video');
    // Hide questions
    $('.overlay-video').fadeOut();
    $('#videoQuestion')[0].play();
  });

    // show text choices
    $('.show-text-choices').click(function(e) {
      e.preventDefault;
      // show choices
      $('.text-choices').fadeIn();
      // setTimeout so that fadeIn completes before contentHeight
      setTimeout(contentHeight, 100);
    });

  // Show quiz on videos end
  $('video').on('ended',function(){
    // console.log('Video has ended & questions revealed');
    $('.overlay-video').fadeIn('slow');
    // $('.replayVideo').removeClass("disabled");
  });

  // Set height of video overlay
  var divHeight = $('.embed-responsive').height();
  $('.overlayHeight').css('min-height', divHeight+'px');

  // Redo set height of overlay on Resize
  var doit;
  function resizedw(){
    var divHeight = $('.embed-responsive').height();
    $('.overlayHeight').css('min-height', divHeight+'px');
  }
  window.onresize = function() {
    clearTimeout(doit);
    doit = setTimeout(function() {
      resizedw();
    }, 100);
  };
}

// Play only 1 feedback video at a time
if ($(".simulation .feedback").length) {
  $('video').bind('play', function (e){
    var video = $('video');
    for(var i=0;i<video.length;i++){
      if(video[i] != e.target){
       video[i].pause();
      }
    }
  });
}


// START: CHECK LANGUAGE URL AND CHANGE NAV DROPDOWN TO MATCH
$(function() {
  url = window.location.href.split("/")
  let currentLanguage = url[3]

  if (currentLanguage == "en") {
      $("#navbarDropdownLangLink").text("English")
  }
  if (currentLanguage == "fr-ca") {
      $("#navbarDropdownLangLink").text("French Canadian")
      $("body").addClass("lang-fr");
  }
  if (currentLanguage == "pt-br") {
      $("#navbarDropdownLangLink").text("Português")
      $("body").addClass("lang-pt");
  }
  if (currentLanguage == "es") {
      $("#navbarDropdownLangLink").text("Español")
      $("body").addClass("lang-es");
  }
  if (currentLanguage == "zh") {
      $("#navbarDropdownLangLink").text("简体中文")
      $("body").addClass("lang-zh");
  }
  if (currentLanguage == "de") {
      $("#navbarDropdownLangLink").text("Deutsch")
      $("body").addClass("lang-de");
  }
  if (currentLanguage == "ru") {
      $("#navbarDropdownLangLink").text("Pусский")
      $("body").addClass("lang-ru");
  }
  if (currentLanguage == "ja") {
      $("#navbarDropdownLangLink").text("日本語")
      $("body").addClass("lang-ja");
  }
  if (currentLanguage == "tr") {
      $("#navbarDropdownLangLink").text("Türkce")
      $("body").addClass("lang-tr");
  }
});

// END: CHECK LANGUAGE URL AND CHANGE NAV DROPDOWN TO MATCH



// START: LEARNING JOURNEY
$(function() {
  $('.learning_journey .lj-arrow-row:not(:last)').append('<div class="lj-arrow-curve"></div>');

  $('.learning_journey .lj-arrow-row:last').append('<div class="lj-arrow-point"></div>');

  $('.learning_journey .lj-arrow-curve').each(function(index) {
    if (index % 2 === 0) {
      $(this).addClass('right');
    }
    else {
      $(this).addClass('left');
    }
  });

  $('.learning_journey .lj-arrow-row').each(function(index) {
    if (index % 2 === 0) {
      $(this).addClass('right');
      $(this).find('.lj-arrow-point').addClass('right');
    }
    else {
      $(this).addClass('left');
      $(this).find('.lj-arrow-point').addClass('left');
    }
  });

  $('.learning_journey .lj-arrow').show();

  if ( $( ".learning_journey" ).length ) {
    $('.container-wrapper').css("background-color", "white");
  }

  // sidebar section
  $('.learning_journey .top-nav .list-group-item').each(function() {
    if ($(this).hasClass('active')) {
      var sectionName = $(this).text()
      $('#sectionName').text(sectionName);
    }
  });



  // completed footer icon
  $('.learning_journey .card.completed').each(function() {
    $(this).find('.footer-icon').append('<i class="fas fa-check-double"></i>');
  });
  $('.learning_journey .card.Complete').each(function() {
    $(this).find('.footer-icon').append('<i class="fas fa-check-double"></i>');
  });

  // completed sidebar icon
  $('.learning_journey .journey-container .side-nav .list-group-item').each(function() {
    if ($(this).hasClass('completed')) {
      $(this).find('.side-nav-icon').append('<i class="fas fa-check-double"></i>');
    }
    else if ($(this).hasClass('Complete')) {
      $(this).find('.side-nav-icon').append('<i class="fas fa-check-double"></i>');
    }
    else {
      $(this).find('.side-nav-icon').append('<i class="far fa-circle"></i>');
    }
  });

  // hide progress bullet on overview
  $('.learning_journey .card .card-footer .footer-date').each(function() {
    $(this).html($(this).html().replace("&nbsp;&nbsp;•&nbsp;&nbsp;",""));
    $(this).html($(this).html().replace("-"," "));
  });

  // note data ajax  get-data.
  $(document).ready(getNote);

  function getNote () {
    if($('#user-note-text').is(':visible')){
      var url = $('.user-note-button').attr("data-url");
      $.ajax({
        type: "GET",
        url: url,
        success: function(data) {
          $("#user-note-text").val(data.note);
        }
      });
    } else {
      setTimeout(getNote, 50);
    }
  }

  // post note
  $(".user-note-button").click(function() {
    $('.user-note-button').text("Saving...");
    var url = $('.user-note-button').attr("data-url");
    var textarea = $('#user-note-text').val();
    var category = $('#user-note-category').val();
    var data = { note: textarea, category: category }
    $.ajax({
      url: url,
      data: JSON.stringify(data),
      dataType: "json",
      type: "POST",
      success: function () {
        // console.log(data)
        $('.user-note-button').animate({opacity:.5},function(){
          $(this).text("Saved!").animate({opacity:1});
        });
        setTimeout(function() {
					$('.user-note-button').text("Save");
				}, 4000 );
      }
    });

  });

  // open first path group by default
  var ljPathSectionFirst = $(".learning_journey .journey-container .path-group .path-group-section").first();
  var ljListGroupFirst = $(".learning_journey .journey-container .path-group .list-group").first();

  ljPathSectionFirst.find('.btn-collapse').removeClass('collapsed');
  ljPathSectionFirst.find('.btn-lj-show-text').hide();
  ljPathSectionFirst.find('.btn-lj-hide-text').show();
  ljPathSectionFirst.find('.fa-angle-down').removeClass('fa-angle-down').addClass('fa-angle-up');

  ljListGroupFirst.addClass('show');

  if ($(".learning_journey")[0]){
    contentHeight();
  }

});
// END: LEARNING JOURNEY
