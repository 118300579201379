<template>
  <div class="row mb-5">
    <div class="col-12 col-lg-4">

      <a @click="selectTab(tab.hash,index)" v-for="(tab, index) in tabs" class="card card-tab"
         :class="{active: selectedTab === tab.hash}" :aria-expanded="(selectedTab === tab.hash).toString()">
        <div class="row align-items-center no-gutters">
          <div class="col-3 py-2 text-center">
            <img :src="tab.image" class="img-fluid"
                 :alt="tab.title">
          </div>
          <div class="col">
            <div class="card-body" id="headingOne">
              <h5 class="card-title mb-0">{{ tab.title }}</h5>
              <p class="card-text">{{ tab.label }}</p>
            </div>
          </div>
        </div>
      </a>

    </div>
    <div class="col-12 col-lg-8">
      <div class="one-twenty-three h-100"
           :style="backgroundimg">
        <a @click="prev()" class="tab-nav-icon">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24">
            <path d="M17.2 23.7 5.4 12 17.2.3l1.3 1.4L8.4 12l10.1 10.3z"/>
          </svg>
        </a>
        <div class="audio-content-wrapper">
          <slot>

          </slot>
        </div>
        <a @click="next()" class="tab-nav-icon">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24">
            <path d="m6.8 23.7-1.4-1.4L15.7 12 5.4 1.7 6.8.3 18.5 12z"/>
          </svg>
        </a>
      </div>
    </div>
  </div>

</template>
<script lang="js" setup>
import { provide, reactive, ref } from "vue"

const selectedTab = ref("1")
const tabIndex = ref(0)
const tabs = reactive([])
provide("selectedTab", selectedTab)
provide("tabs", tabs)

const props = defineProps({
  backgroundimg: {
    type: String,
    default: "",
  },
})

function selectTab(hash, index) {
  selectedTab.value = hash
  tabIndex.value = index
}

function next() {
  tabIndex.value = (tabIndex.value + 1) % tabs.length
  selectedTab.value = tabs[tabIndex.value].hash
}

function prev() {
  tabIndex.value = (tabIndex.value - 1 + tabs.length) % tabs.length
  selectedTab.value = tabs[tabIndex.value].hash
}

</script>
<style lang="scss">
.tab-nav-icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  fill: #ffffff;
  margin: 0 2.25rem;

  @media screen and (max-width: 991px) {
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
    margin: 0 0.75rem;
  }

  &:hover {
   fill: #00B4ED;
  }
}
</style>
