$(function() {
  if ($(".resource").length) {
    const resource_obj = $(".resource");
    const endpoint = resource_obj.attr("endpoint");
    const obj_id = resource_obj.attr("data-obj-id");
    const obj_type = resource_obj.attr("data-obj-type");
    const data = JSON.stringify({
      obj_type: obj_type,
      obj_id: obj_id,
    });
    // console.log(data);
    // console.log(endpoint);

    $.ajax({
      type: "POST",
      url: endpoint,
      dataType: "json",
      data: data,
      success: function(data) {
        // console.log('success')
      }
    });
  }
});
