import {reactive, ref} from "vue";
import {apiClient} from "@/js/client";
import axios from "axios";
import {data} from "autoprefixer";
import {useDebounceFn} from "@vueuse/core";

export function useJournalData<T extends object>(journalId: string, journalType: string, def: T) {
    const journalData = reactive<T>(def)
    const initialized = ref(false)
    const id = ref("")
    async function fetch() {
        try {
            const { data } = await apiClient.get(`/journal/api/journal/${journalId}/?obj_id=${journalId}&obj_type=${journalType}`)
            if (data) {
                Object.assign(journalData, data.data as T)
                id.value = data.id
            }
            initialized.value = true
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    id.value = ""
                    initialized.value = true
                    return
                }
            }
            throw e
        }
    }


    async function update() {
        if (initialized.value) {
            if (!id.value) {
                const { data } = await apiClient.post(`/journal/api/journal/`, { obj_id: journalId, data: journalData, obj_type: journalType ,  })
                Object.assign(journalData, data.data as T)
                id.value = data.id
            } else {
                await apiClient.put(`/journal/api/journal/${journalId}/?obj_id=${journalId}&obj_type=${journalType}`, {
                    data: journalData,
                    obj_id: journalId,
                    obj_type: 'journal',
                })
            }
        }
    }

    const debouncedUpdate = useDebounceFn(
        async () => {
            await update();
        },
        1000,
    );



    return {
        journalData,
        fetch,
        update,
        debouncedUpdate

    }
}
